import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Spinner, Alert, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Click2payTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  const fetchOrders = useCallback(async () => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        const response = await axios.get('/api/clic2pay/order-statuses');
        const uniqueOrders = response.data.reduce((acc, order) => {
          if (!acc.find((o) => o.orderId === order.orderId)) {
            acc.push(order);
          }
          return acc;
        }, []);
        setOrders(uniqueOrders);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          setError('Error fetching order statuses.');
          console.error('Error:', error.message);
        }
      }
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    fetchOrders();
    const interval = setInterval(fetchOrders, 60000);
    return () => clearInterval(interval);
  }, [fetchOrders]);

  if (loading)
    return (
      <Spinner animation='border' role='status' className='my-3'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    );

  if (error) return <Alert variant='danger'>{error}</Alert>;

  return (
    <Container>
      <h2 className='my-4'>Table Status de Paiement Click2Pay</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Montant</th>
            <th>Client</th>
            <th>Error Code</th>
            <th>Error Message</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderId}>
              <td>{order.orderId}</td>
              <td>{order.OrderStatus}</td>
              <td>{(order.Amount / 1000).toFixed(2)} DT</td>
              <td>{order.cardholderName || 'N/A'}</td>
              <td>{order.ErrorCode || 'N/A'}</td>
              <td>{order.ErrorMessage || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Click2payTable;
