import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { Container } from 'react-bootstrap';
/*
//selim
import { useSelector, useDispatch } from 'react-redux';
//import {usePayOrderMutation} from '../slices/ordersApiSlice';
import { useClictopayMutation } from '../slices/ordersApiSlice';
import { setCredentialOrder } from '../slices/orderSlice';
//import { Button } from 'react-bootstrap';
*/

//couleur
//import { useSelector, useDispatch } from 'react-redux';
//import { addToClictopay } from '../slices/clictopaySlice';

const PaymentSuccessfulScreen = () => {
  const { t } = useTranslation();

  /*
  //selim
  //const [payOrder] = usePayOrderMutation();
  const dispatch = useDispatch();
  const [clictopay] = useClictopayMutation();
  var retour_payment = "Success";
  const { orderInfo } = useSelector((state) => state.order);

  var idOrder = orderInfo.arg.originalArgs.orderId
  var detail = orderInfo.arg.originalArgs.paiement
  var reponseErreur = 0;
  */

  //dispatch(setCredentials({...idOrder, retour_payment}));

  //couleur
  //const { clictopayInfo } = useSelector((state) => state.clictopay);
  //const dispatch = useDispatch();
  //var paiement = "Success";
  //var orderId = clictopayInfo.orderId;
  //console.log(clictopayInfo);
  //console.log(orderId);
  //console.log(clictopayInfo.paiement);
  //console.log(paiement);

  //couleur
  //dispatch(addToClictopay({ ...{orderId, paiement} }));
  //dispatch(addToClictopay({ orderId, paiement }));

  useEffect(() => {
    //const res = payOrder({ idOrder, details: {payer: retour_payment }});
    const redirectTimeout = setTimeout(() => {
      //window.location.href = 'http://localhost:3000/Success';
      window.location.href = 'https://licorne-academy.store/Success';
    }, 5000);

    //console.log(idOrder);
    /*
        //selim
        const res2 = clictopay({ idOrder, reponseErreur, detail });
        dispatch(setCredentialOrder({ ...res2, }));
        */

    return () => {
      clearTimeout(redirectTimeout);
    };
  });

  // const handleClick = async (event) => {
  //   event.preventDefault();
  // };
  //dispatch(addToClictopay({ ...{paiement} }));

  return (
    <>
      <Container>
        <h1 className='text-center'>{t('paysucc')}</h1>
        {/* <form onSubmit={handleClick}>
        <Button type='submit' className='btn-block'>
        {('Rediriger')}
        </Button>
      </form> */}
      </Container>
    </>
  );
};

export default PaymentSuccessfulScreen;
