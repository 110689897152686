import React, { useState } from 'react';
import axios from 'axios';
import { Button, Spinner, Alert, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addToClictopay } from '../slices/clictopaySlice';
import { usePayOrderMutation } from '../slices/ordersApiSlice';
import { useTranslation } from 'react-i18next';

const ClickToPay = ({ amount, orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formUrl, setFormUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [payOrder] = usePayOrderMutation();

  const handlePayment = async () => {
    setLoading(true);
    setError('');
    try {
      // Make a POST request to the backend to register the order
      const response = await axios.post(
        `/api/clic2pay/registerOrder/${amount}`
      );
      console.log('Response from server:', response.data);

      // If the backend response is successful, get the formUrl and redirect
      if (response.data.success) {
        setFormUrl(response.data.formUrl);

        // Dispatch payment information to Redux store
        dispatch(addToClictopay({ orderId, paiement: 'depart' }));
        await payOrder({ orderId, details: { payer: true } });

        // Redirect the user to the payment gateway formUrl
        window.location.href = response.data.formUrl;
      } else {
        throw new Error(response.data.error || 'Unknown error occurred');
      }
    } catch (error) {
      setError(
        error.response?.data?.error ||
          error.message ||
          'Error registering order. Please try again.'
      );
      console.error('Error registering order:', error);
    }
    setLoading(false);
  };

  return (
    <Container className='my-4'>
      <h2>{t('new_order')}</h2>
      <Button onClick={handlePayment} disabled={loading} variant='primary'>
        {loading ? (
          <>
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />{' '}
            {t('processing')}
          </>
        ) : (
          t('pay_now')
        )}
      </Button>
      {error && (
        <Alert variant='danger' className='mt-3'>
          {error}
        </Alert>
      )}
      {formUrl && (
        <Alert variant='success' className='mt-3'>
          <p>{t('click_link_to_pay')}:</p>
          <a href={formUrl} target='_blank' rel='noopener noreferrer'>
            {t('complete_payment')}
          </a>
        </Alert>
      )}
    </Container>
  );
};

export default ClickToPay;
