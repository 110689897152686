//selim
import { useSelector } from 'react-redux';

import { Link, useParams } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card } from 'react-bootstrap';

//import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';

import { useGetOrderDetailsQuery } from '../slices/ordersApiSlice';

import ClickToPay from './ClickToPay';

const OrderScreen = () => {
  const { id: orderId } = useParams();

  const {
    data: order,
    //refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);

  //selim
  const { deviseInfo } = useSelector((state) => state.devise);

  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';
  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  // TESTING ONLY! REMOVE BEFORE PRODUCTION
  //  async function onApproveTest() {
  //    await payOrder({ orderId, details: { payer: {} } });
  //  refetch();
  //  toast.success('Order is paid');
  //  }

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <h1>Commande {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item dir={isRTL ? 'rtl' : 'ltr'}>
              <h2>{t('orders')}</h2>
              <p>
                <strong>{t('name')} : </strong>{' '}
                {order.shippingAddress.namePrename}
              </p>
              <p>
                <strong> {t('email')} : </strong>{' '}
                <a href={`mailto:${order.shippingAddress.mail}`}>
                  {order.shippingAddress.mail}
                </a>
              </p>
              <p>
                <strong>{t('address')} </strong>
                {order.shippingAddress.adress}
              </p>
              <p>
                <strong>{t('phonenumber')} : </strong>
                {order.shippingAddress.tel}
              </p>

              {/* {order.isDelivered ? (
                <Message variant='success'>
                  Délivré le {order.deliveredAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Delivered</Message>
              )} */}
            </ListGroup.Item>

            <ListGroup.Item dir={isRTL ? 'rtl' : 'ltr'}>
              <h2>{t('mode')}</h2>
              <Col>
                <p>
                  <strong>{t('mode')} : </strong>
                  {order.paymentMethod}
                </p>
              </Col>
              <Col>
                <strong>{t('devise')} : </strong>
                {deviseInfo}
              </Col>
              {order.isPaid ? (
                <Message variant='success'>Payé le {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>N'est pas payé</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t('ordsum')}</h2>
              {order.orderItems.length === 0 ? (
                <Message>{t('emptycart')}</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>

                        {/* selim */}
                        {/* <Col md={4}>
                              {item.qty} x ${item.price} = ${item.qty * item.price}
                            </Col> */}
                        {deviseInfo === 'D_tn' && (
                          <Col md={5}>
                            {t('Price')}
                            {item.qty} x ${item.price} = {item.qty * item.price}{' '}
                            {deviseInfo}
                          </Col>
                        )}
                        {deviseInfo === 'Euro' && (
                          <Col md={5}>
                            {t('Price')}
                            {item.qty} x ${item.priceEur} ={' '}
                            {item.qty * item.priceEur} {deviseInfo}
                          </Col>
                        )}
                        {deviseInfo === 'Dolar' && (
                          <Col md={5}>
                            {t('Price')}
                            {item.qty} x ${item.priceDol} ={' '}
                            {item.qty * item.priceDol} {deviseInfo}
                          </Col>
                        )}
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2 dir={isRTL ? 'rtl' : 'ltr'}>{t('ordsum')} </h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t('Price')} </Col>

                  {/* selim */}
                  {/* <Col>${order.itemsPrice}</Col> */}
                  {deviseInfo === 'D_tn' && (
                    <Col>
                      <strong>
                        {order.itemsPrice} {deviseInfo}
                      </strong>
                    </Col>
                  )}
                  {deviseInfo === 'Euro' && (
                    <Col>
                      <strong>
                        {order.itemsPriceEur} {deviseInfo}
                      </strong>
                    </Col>
                  )}
                  {deviseInfo === 'Dolar' && (
                    <Col>
                      <strong>
                        {order.itemsPriceDol} {deviseInfo}
                      </strong>
                    </Col>
                  )}
                </Row>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col>Livraison</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                   <Col>${order.totalPrice}</Col> 
                   selim 
                      {deviseInfo === "D_tn" && (
                      <Col>
                        <strong>{order.totalPrice} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Euro" && (
                      <Col>
                        <strong>{order.totalPriceEur} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Dolar" && (
                      <Col>
                        <strong>{order.totalPriceDol} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                </Row>
              </ListGroup.Item> */}

              {!order.isPaid && (
                <ListGroup.Item>
                  {/* {<Loader />} */}

                  <div>
                    {/* THIS BUTTON IS FOR TESTING! REMOVE BEFORE PRODUCTION! */}
                    {/* <Button
                        style={{ marginBottom: '10px' }}
                        onClick={onApproveTest}
                       >
                        Test Pay Order
                       </Button>  */}

                    {/* selim */}
                    {deviseInfo === 'D_tn' ? (
                      <ClickToPay
                        //onClick={handleClick}
                        // onSuccess={successPaymentHandler}
                        amount={order.totalPrice}
                        orderId={orderId}
                        createOrder={createOrder}
                      />
                    ) : (
                      <h6>La devise de paiement doit être en dinar Tunisien</h6>
                    )}
                  </div>
                </ListGroup.Item>
              )}

              {/* {loadingDeliver && <Loader />}

              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                 <ListGroup.Item>
                   <Button
                   type='button'
                   className='btn btn-block'
                   onClick={deliverOrderHandler}
                   >
                   Mark As Delivered
                  </Button>
                </ListGroup.Item>
              )} */}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
