import React, { useState, useEffect } from 'react';
import { Col, Card, Button, Modal, Form, Spinner, Row } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Videos.css';
import { useSelector } from 'react-redux';

const Playlist = () => {
  const [playlists, setPlaylists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const playlistsPerPage = 6;
  const [showModal, setShowModal] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [newPlaylistDescription, setNewPlaylistDescription] = useState('');
  const [newPlaylistImage, setNewPlaylistImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  const fetchPlaylists = async () => {
    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.isOffice || userInfo.isFormateur)
    ) {
      try {
        const response = await axios.get('/api/playlists', {
          withCredentials: true,
        });
        setPlaylists(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        console.error('Error fetching playlists:', error.message);
      }
    } else {
      navigate('/login');
    }
  };

  const handlePlaylistAdd = async () => {
    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.isOffice || userInfo.isFormateur)
    ) {
      const formData = new FormData();
      formData.append('name', newPlaylistName);
      formData.append('description', newPlaylistDescription);
      formData.append('image', newPlaylistImage);

      try {
        setLoading(true);
        await axios.post('/api/playlists', formData, {
          withCredentials: true,
        });
        fetchPlaylists();
        handleCloseModal();
      } catch (error) {
        setError('Failed to add playlist. Please try again.');
        console.error('Error adding playlist:', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/login');
    }
  };

  const handlePlaylistDelete = async (playlistId) => {
    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.isOffice || userInfo.isFormateur)
    ) {
      try {
        setLoading(true);
        await axios.delete(`/api/playlists/${playlistId}`, {
          withCredentials: true,
        });
        fetchPlaylists();
      } catch (error) {
        console.error('Error deleting playlist:', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/login');
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewPlaylistName('');
    setNewPlaylistDescription('');
    setNewPlaylistImage(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the current playlists to display
  const indexOfLastPlaylist = currentPage * playlistsPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - playlistsPerPage;
  const currentPlaylists = playlists.slice(
    indexOfFirstPlaylist,
    indexOfLastPlaylist
  );

  const totalPages = Math.ceil(playlists.length / playlistsPerPage);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.isOffice || userInfo.isFormateur)
    ) {
      fetchPlaylists();
    } else {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col>
      <h1>Playlist Management</h1>
      <Button variant='success' onClick={handleShowModal}>
        Add Playlist
      </Button>
      {error && <p className='error'>{error}</p>}
      <Row>
        {currentPlaylists.map((playlist) => (
          <Col key={playlist._id} md={4}>
            <Card>
              <Card.Img
                variant='top'
                src={`${playlist.image}`}
                style={{
                  maxWidth: '100%',
                  height: '350px',
                  objectFit: 'cover',
                }}
              />
              <Card.Body>
                <Card.Title>{playlist.name}</Card.Title>
                <Card.Text>{playlist.description}</Card.Text>
                {userInfo.isAdmin && (
                  <Button
                    variant='danger'
                    onClick={() => handlePlaylistDelete(playlist._id)}
                  >
                    Delete Playlist
                  </Button>
                )}
                <Link
                  to={
                    userInfo.isFormateur
                      ? `/formateur/playlists/${playlist._id}/videos`
                      : `/admin/playlists/${playlist._id}/videos`
                  }
                >
                  <Button
                    variant='info'
                    style={{
                      marginBottom: '-10px',
                    }}
                  >
                    View Videos
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div className='pagination'>
        {[...Array(totalPages).keys()].map((page) => (
          <Button
            key={page + 1}
            variant={currentPage === page + 1 ? 'primary' : 'outline-primary'}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Button>
        ))}
      </div>

      {/* Modal for adding a playlist */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='playlistName'>
              <Form.Label>Playlist Name</Form.Label>
              <Form.Control
                type='text'
                value={newPlaylistName}
                onChange={(e) => setNewPlaylistName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='playlistDescription'>
              <Form.Label>Playlist Description</Form.Label>
              <Form.Control
                type='text'
                value={newPlaylistDescription}
                onChange={(e) => setNewPlaylistDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='playlistImage'>
              <Form.Label>Playlist Image</Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => setNewPlaylistImage(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={handlePlaylistAdd}
            disabled={loading}
          >
            {loading && (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            )}{' '}
            Add Playlist
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default Playlist;
