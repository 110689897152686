import React from 'react';
import axios from 'axios';
import { Container, Button, Form } from 'react-bootstrap';

function Paypal({ amount }) {

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!amount || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }

    try {
      const res = await axios.post('/api/paypal', { amount });
      if (res && res.data) {
        window.location.href = res.data.links[1].href; // Redirect to PayPal for payment
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container className='d-flex flex-column align-items-center justify-content-center'>
      <Form onSubmit={handlePayment} className='mt-3'>
        <Button variant='primary' type='submit' className='mt-3'>
          Proceed to Payment
        </Button>
      </Form>
    </Container>
  );
}

export default Paypal;
