import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const PaypalSuccess = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      const queryParams = new URLSearchParams(location.search);
      const PayerID = queryParams.get('PayerID');
      const paymentId = queryParams.get('paymentId');

      try {
        const response = await fetch(
          `/api/paypal/success?PayerID=${PayerID}&paymentId=${paymentId}`
        );
        const data = await response.json();
        setPaymentDetails(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payment details:', error);
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [location.search]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://licorne-academy.store/';
    }, 10000); // Redirect after 10 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    );
  }

  if (!paymentDetails) {
    return <p>Error loading payment details. Please try again.</p>;
  }

  return (
    <Container className='mt-5'>
      <Card>
        <Card.Body>
          <Card.Title as='h4' className='text-success'>
            Payment Successful!
          </Card.Title>
          <hr />
          <p>Thank you for your payment. Here are your payment details:</p>
          <hr />
          <p>
            <strong>Transaction ID:</strong> {paymentDetails.id || 'N/A'}
          </p>
          <p>
            <strong>Payer Name:</strong>{' '}
            {paymentDetails.payer?.payer_info?.first_name}{' '}
            {paymentDetails.payer?.payer_info?.last_name}
          </p>
          <p>
            <strong>Email:</strong> {paymentDetails.payer?.payer_info?.email}
          </p>
          <p>
            <strong>Amount:</strong>{' '}
            {paymentDetails.transactions[0]?.amount?.total}{' '}
            {paymentDetails.transactions[0]?.amount?.currency}
          </p>
          <hr />
          <Button variant='primary' onClick={handlePrint} className='w-100'>
            Print Receipt
          </Button>
          <p className='text-center mt-3'>
            Redirecting to{' '}
            <a href='https://licorne-academy.store/'>Licorne Academy</a> in 10
            seconds...
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PaypalSuccess;
