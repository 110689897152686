//export const BASE_URL = process.env.NODE_ENV === 'development' ?'http://localhost:5000' : '';
export const BASE_URL = '';

export const PRODUCTS_URL = '/api/products';
export const USERS_URL = '/api/users';
export const ORDERS_URL = '/api/orders';
//NEW-VENTE
export const ORDERSOFFICE_URL = '/api/ordersOffice';
export const PAYPAL_URL = '/api/config/paypal';
export const UPLOAD_URL = '/api/upload';
