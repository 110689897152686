import React from 'react'
import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

const VendeurRoute = () => {
  const { userInfo } = useSelector(state => state.auth)

  return userInfo && userInfo.isVendeur ? (
  <Outlet /> 
  ) : ( <Navigate to="/login" replace />
  );
}

export default VendeurRoute