import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Spinner, Alert, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PaypalPaymentsTable() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  const fetchPayments = useCallback(async () => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        const response = await axios.get('/api/paypal/payments');
        setPayments(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          setError('Error fetching PayPal payments.');
          console.error('Error:', error.message);
        }
      }
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    fetchPayments();
    const interval = setInterval(fetchPayments, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, [fetchPayments]);

  if (loading)
    return (
      <Spinner animation='border' role='status' className='my-3'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    );

  if (error) return <Alert variant='danger'>{error}</Alert>;

  return (
    <Container>
      <h2 className='my-4'>PayPal Payments</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Payer Email</th>
            <th>Payer Name</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Status</th>
            <th>Transaction Time</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.paymentId}>
              <td>{payment.paymentId}</td>
              <td>{payment.payerEmail}</td>
              <td>{`${payment.payerFirstName} ${payment.payerLastName}`}</td>
              <td>{payment.amount}</td>
              <td>{payment.currency}</td>
              <td>{payment.paymentStatus}</td>
              <td>{new Date(payment.paymentTime).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default PaypalPaymentsTable;
