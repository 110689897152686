import React from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

const CheckoutStripe = ({ amount }) => {


  const handleCheckout = async () => {
    try {
      const response = await axios.post('/api/create-checkout-session', {
        amount: amount * 100, // Convert to cents
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating checkout session', error);
    }
  };

  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col md={6}>
          <Form>
            <Button variant='primary' onClick={handleCheckout}>
              Checkout
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutStripe;
