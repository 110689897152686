import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
//import products from '../products'
//import {useEffect, useState} from 'react';
//import axios from 'axios';

import Paginate from '../components/Paginate';
//import ProductCarousel from '../components/ProductCarousel'
import ContactForm from '../components/ContactForm';

import { useGetProductsQuery } from '../slices/productsApiSlice';
import ProductPresential from '../components/ProductPresential';

//paiement
// import { useSelector, useDispatch } from 'react-redux';
// import {usePayOrderMutation} from '../slices/ordersApiSlice';
// import { setCredentials } from '../slices/orderSlice';

import { useTranslation } from 'react-i18next';

const HomeScreenPresential = () => {
  //window.location.reload();

  const { pageNumber, keyword } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, error } = useGetProductsQuery({
    pageNumber,
    keyword,
  });

  //const dispatch = useDispatch();
  //paiement
  //const [payOrder] = usePayOrderMutation();
  //const order = useSelector((state) => state.order);
  //const { orderInfo } = order;
  //console.log("test");
  //console.log(orderInfo.arg.originalArgs.orderId);
  //console.log(orderInfo.arg.originalArgs.details.payer);
  //var idOrder = orderInfo.arg.originalArgs.orderId;
  //var ErreurReponse = orderInfo.arg.originalArgs.details.payer;
  //if(ErreurReponse === 0)
  // {
  //const res = payOrder({ idOrder, details: {payer: ErreurReponse }});
  //dispatch(setCredentials({...res}));
  //console.log(idOrder);
  //console.log(ErreurReponse);
  //console.log("test ok");
  //}

  return (
    <>
      {/* { !keyword ? <ProductCarousel /> : (
    <Link to='/' className='btn btn-light mb-4'> Go Back </Link> 
    )} */}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}{' '}
        </Message>
      ) : (
        <>
          <hr></hr>
          <h1 align='center'> {t('homeTitle')} </h1>
          <hr color='green'></hr>
          {/* <Row>
           {data.products.map((product) => (
               <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                {product.type === 0 && (
                
                 <ProductPresential product={product} />
             
                )}
               </Col>
           ))}
        </Row> 
        NEW-VENTE*/}
          <Row>
            {data.products.map(
              (product) =>
                product.type === 0 && (
                  <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                    <ProductPresential product={product} />
                  </Col>
                )
            )}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ''}
          />
          <ContactForm />
        </>
      )}
    </>
  );
};

export default HomeScreenPresential;
