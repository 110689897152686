import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

function PaypalFail() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://licorne-academy.store/';
    }, 10000); // Redirect after 10 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <Container
      className='d-flex flex-column align-items-center justify-content-center'
      style={{ minHeight: '100vh' }}
    >
      <ExclamationTriangleFill color='red' size={80} />
      <h4 className='mt-3'>Payment Failed</h4>
      <p>
        Unfortunately, your payment could not be processed. Please try again or
        contact support.
      </p>
      <p className='text-center mt-3'>
        Redirecting to{' '}
        <a href='https://licorne-academy.store/'>Licorne Academy</a> in 10
        seconds...
      </p>
    </Container>
  );
}

export default PaypalFail;
