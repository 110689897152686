import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Paginate from '../../components/Paginate';
import {
  useGetProductsQuery,
  useCreateProductFormateurMutation, // Import the mutation hook
} from '../../slices/productsApiSlice';
import moment from 'moment';

const ProductListScreenFormateur = () => {
  const { pageNumber } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetProductsQuery({
    pageNumber,
    sortBy: 'position_asc',
  });

  const [createProductFormateur, { isLoading: loadingCreate }] =
    useCreateProductFormateurMutation();

  const createProductFormateurHandler = async () => {
    if (
      window.confirm('Are you sure you want to create a Formateur product?')
    ) {
      try {
        await createProductFormateur();
        window.location.reload(); // Reload to reflect the new product
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  const editProductHandler = (id) => {
    navigate(`/formateur/product/${id}/edit`);
  };

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Formateur Products</h1>
        </Col>
        <Col className='text-end'>
          <Button
            className='btn-sm m-3'
            onClick={createProductFormateurHandler}
          >
            <FaPlus /> Add Formateur Product
          </Button>
        </Col>
      </Row>

      {loadingCreate && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Position</th>
                <th>Name</th>
                <th>Type</th>
                <th>Proprietaire</th>
                <th>D_TN</th>
                <th>Euro</th>
                <th>Dolar</th>
                <th>Date</th>
                <th>Category</th>
                <th>Brand</th>
                <th>Stock</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.products
                .filter((product) => product.type === 1) // Filter for Formateur type
                .map((product) => (
                  <tr key={product._id}>
                    <td>{product.position}</td>
                    <td>{product.name}</td>
                    <td>{product.type}</td>
                    <td>{product.proprio}</td>
                    <td>{product.price}</td>
                    <td>{product.priceEur}</td>
                    <td>{product.priceDol}</td>
                    <td>{moment(product.date).format('DD MMMM YYYY')}</td>
                    <td>{product.category}</td>
                    <td>{product.brand}</td>
                    <td>{product.countInStock}</td>
                    <td>
                      <Button
                        variant='light'
                        className='btn-sm mx-2'
                        onClick={() => editProductHandler(product._id)}
                      >
                        <FaEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Paginate pages={data.pages} page={data.page} />
        </>
      )}
    </>
  );
};

export default ProductListScreenFormateur;
