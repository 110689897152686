export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state) => {
  //calculer items price
  state.itemsPrice = addDecimals(
    state.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );

  //selim
  state.itemsPriceEur = addDecimals(
    state.cartItems.reduce((acc, item) => acc + item.priceEur * item.qty, 0)
  );
  state.itemsPriceDol = addDecimals(
    state.cartItems.reduce((acc, item) => acc + item.priceDol * item.qty, 0)
  );

  //calculer shipping price (if order is over 100dollars then free
  //else 10$ shipping)
  state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 0);
  //prob price
  state.shippingPriceEur = addDecimals(state.itemsPriceEur > 100 ? 0 : 0);
  state.shippingPriceDol = addDecimals(state.itemsPriceDol > 100 ? 0 : 0);

  //calculer tax price (15% tax)
  state.taxPrice = addDecimals(Number((0 * state.itemsPrice).toFixed(2)));
  //prob price
  state.taxPriceEur = addDecimals(Number((0 * state.itemsPriceEur).toFixed(2)));
  state.taxPriceDol = addDecimals(Number((0 * state.itemsPriceDol).toFixed(2)));

  //calculer total price
  state.totalPrice = (
    Number(state.itemsPrice) +
    Number(state.shippingPrice) +
    Number(state.taxPrice)
  ).toFixed(2);

  //prob price
  state.totalPriceEur = (
    Number(state.itemsPriceEur) +
    Number(state.shippingPriceEur) +
    Number(state.taxPriceEur)
  ).toFixed(2);
  state.totalPriceDol = (
    Number(state.itemsPriceDol) +
    Number(state.shippingPriceDol) +
    Number(state.taxPriceDol)
  ).toFixed(2);

  //enregistrer dans le local storage
  localStorage.setItem('cart', JSON.stringify(state));

  return state;
};
