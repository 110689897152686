import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Spinner, Alert, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function TransactionsTable() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const fetchTransactions = useCallback(async () => {
    try {
      const response = await axios.get('/api/transactions');
      setTransactions(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        setError('Error fetching transactions.');
        console.error('Error:', error.message);
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchTransactions();
    const interval = setInterval(fetchTransactions, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, [fetchTransactions]);

  if (loading)
    return (
      <Spinner animation='border' role='status' className='my-3'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    );

  if (error) return <Alert variant='danger'>{error}</Alert>;

  return (
    <Container>
      <h2 className='my-4'>Stripe Payments</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.transactionID}>
              <td>{transaction.transactionID}</td>
              <td>{transaction.name}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default TransactionsTable;
