import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchBox = () => {
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (keyword.trim()) {
      navigate(`/search/${keyword}`);
    } else {
      navigate('/');
    }
  }, [keyword, navigate]);

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <Form className='d-flex'>
      <Form.Control
        type='text'
        name='q'
        onChange={handleInputChange}
        value={keyword}
        placeholder={t('searchby')}
      />
      <Button type='button' variant='outline-success' className='p-2'>
        {t('search')}
      </Button>
    </Form>
  );
};

export default SearchBox;
