import { useState, useEffect } from 'react';

import {
  Badge,
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';
import {
  FaJenkins,
  FaUser,
  FaUserLock,
  FaFlag,
  FaMoneyBill,
  FaCartPlus,
} from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBox from './SearchBox';
//couleur
//import { Us, Fr, Tn } from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import '../assets/styles/index.css';

import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../slices/usersApiSlice';

import { removeFromCartLocalStorage } from '../slices/cartSlice';

//couleur
import { setCredentialLangue } from '../slices/langueSlice';

import { setCredentialDevise } from '../slices/deviseSlice';

var espace = '/';

const HeaderMobile = () => {
  //NEW-VENTE
  const [colorFormationPresential, setColorFormationPresential] = useState(
    'formation_presential_green'
  );
  const [colorFormationEnLigne, setColorFormationEnLigne] = useState(
    'formation_EnLigne_purple'
  );
  const [colorAutreProduit, setColorAutreProduit] = useState(
    'autre_produit_purple'
  );

  const [colorPanier, setColorPanier] = useState('panier_red');

  const { cartItems } = useSelector((state) => state.cart);

  const [email, setEmail] = useState('user@user');
  const [password, setPassword] = useState('user');

  const [login] = useLoginMutation();
  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //couleur
  const { langueInfo } = useSelector((state) => state.langue);

  const { deviseInfo } = useSelector((state) => state.devise);
  //const [langue, setLangue] = useState("FR");
  const [colorAuthentificationUser, setColorAuthentificationUser] = useState(
    'colorAuthentificationUser_green'
  );

  useEffect(() => {
    if (userInfo) {
      setColorFormationPresential('formation_presential_green');
      setColorPanier('panier_green');

      //selim
      setColorAuthentificationUser('colorAuthentificationUser_red');

      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const connexion_invite = async () => {
    //e.preventDefault();
    try {
      setColorFormationPresential('formation_presential_green');
      setColorPanier('panier_green');

      setEmail('user@user');
      setPassword('user');
      //console.log("mail"+mail);
      //console.log("pass"+pass);
      //console.log(email);
      //console.log(password);
      const res = await login({ email, password }).unwrap();
      //console.log(res);
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  //NEW-VENTE
  const changement_type_produit = async (type) => {
    try {
      if (type === 1) {
        setColorFormationEnLigne('formation_presential_green');
        setColorFormationPresential('formation_presential_purple');
        setColorAutreProduit('autre_produit_purple');
      } else if (type === 2) {
        setColorFormationEnLigne('formation_presential_purple');
        setColorFormationPresential('formation_presential_purple');
        setColorAutreProduit('autre_produit_green');
      } else if (type === 0) {
        setColorFormationEnLigne('formation_presential_purple');
        setColorFormationPresential('formation_presential_green');
        setColorAutreProduit('autre_produit_purple');
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      //selim
      setColorFormationPresential('formation_presential_green');
      setColorPanier('panier_red');

      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(removeFromCartLocalStorage());
      navigate('/');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <header>
      <Container>
        <Row>
          {/*NEW-VENTE*/}
          <Col className='column_formations_mobile'>
            <LinkContainer
              to='/online'
              onClick={() => changement_type_produit(1)}
            >
              <Nav.Link>
                <h6 className={colorFormationEnLigne}>{t('online')}</h6>
              </Nav.Link>
            </LinkContainer>
          </Col>
          <Col className='column_formations_mobile'>
            <LinkContainer to='/' onClick={() => changement_type_produit(0)}>
              {/* couleur */}
              <Nav.Link>
                {' '}
                <h6 className={colorFormationPresential}>
                  {t('inperson')}
                </h6>{' '}
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col className='column_log_formateur_mobile'>
            {!userInfo && (
              <Nav.Link style={{ color: 'red', fontWeight: 'bold' }}>
                <FaUser /> {t('guest')}
                <Button
                  type='button'
                  variant='light'
                  onClick={connexion_invite}
                >
                  <FaJenkins />
                </Button>
              </Nav.Link>
            )}
            {/*NEW-VENTE*/}
            {userInfo &&
              !userInfo.isAdmin &&
              !userInfo.isOffice &&
              !userInfo.isFormateur &&
              !userInfo.isVendeur &&
              userInfo.name === 'User' && (
                <NavDropdown
                  style={{ color: 'green', fontWeight: 'bold' }}
                  title={userInfo.name}
                  id='username'
                >
                  <NavDropdown.Item onClick={logoutHandler}>
                    {t('Logout')}
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            {/*NEW-VENTE*/}
            {userInfo &&
              !userInfo.isAdmin &&
              !userInfo.isOffice &&
              !userInfo.isFormateur &&
              !userInfo.isVendeur &&
              userInfo.name !== 'User' && (
                <NavDropdown
                  style={{ color: 'green', fontWeight: 'bold' }}
                  title={userInfo.name}
                  id='username'
                >
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    {t('Logout')}
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {/* </LinkContainer> */}

            {userInfo &&
              userInfo.isAdmin &&
              !userInfo.isOffice &&
              !userInfo.isFormateur &&
              !userInfo.isVendeur && (
                <NavDropdown
                  title='Admin'
                  id='adminmenu'
                  style={{ color: 'green', fontWeight: 'bold' }}
                >
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Produits</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Commandes</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/click2paytable'>
                    <NavDropdown.Item> clic2pay Table </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/Paypaltable'>
                    <NavDropdown.Item> Paypal Table </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/Stripetable'>
                    <NavDropdown.Item> Stripe Table </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Utilisateurs</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/cert'>
                    <NavDropdown.Item>Certificate Generator</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/verifycert'>
                    <NavDropdown.Item>
                      {' '}
                      Certificate Verification{' '}
                    </NavDropdown.Item>
                  </LinkContainer>
                  {/* <LinkContainer to='/meet'>
                  <NavDropdown.Item>Meet</NavDropdown.Item>
                </LinkContainer> */}
                  <LinkContainer to='/admin/playlist'>
                    <NavDropdown.Item> Playlist </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Déconnexion
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {userInfo &&
              !userInfo.isAdmin &&
              !userInfo.isFormateur &&
              !userInfo.isVendeur &&
              userInfo.isOffice && (
                <NavDropdown
                  title='Office'
                  id='officemenu'
                  style={{ color: 'green', fontWeight: 'bold' }}
                >
                  <LinkContainer to='/office/productlist'>
                    <NavDropdown.Item>Produits</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/office/cert'>
                    <NavDropdown.Item>Certificate Generator</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/office/verifycert'>
                    <NavDropdown.Item>
                      Certificate Verification
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/office/orderlist'>
                    <NavDropdown.Item>Commandes</NavDropdown.Item>
                  </LinkContainer>
                  {/* <LinkContainer to='/office/playlist'>
                  <NavDropdown.Item> Playlist </NavDropdown.Item>
                </LinkContainer> */}
                  <LinkContainer to='/office/click2paytable'>
                    <NavDropdown.Item> clic2pay Table </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/office/Paypaltable'>
                    <NavDropdown.Item> Paypal Table </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/office/Stripetable'>
                    <NavDropdown.Item> Stripe Table </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Déconnexion
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {userInfo &&
              !userInfo.isAdmin &&
              !userInfo.isVendeur &&
              !userInfo.isOffice &&
              userInfo.isFormateur && (
                <NavDropdown
                  title='Formateur'
                  id='formateurmenu'
                  style={{ color: 'green', fontWeight: 'bold' }}
                >
                  <LinkContainer to='/formateur/productlist'>
                    <NavDropdown.Item>Produits</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/formateur/meet'>
                    <NavDropdown.Item>Meet</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/formateur/playlist'>
                    <NavDropdown.Item> Playlist </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Déconnexion
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {userInfo && !userInfo.isAdmin && userInfo.isVendeur && (
              <NavDropdown
                title='Vendeur'
                id='vendeurmenu'
                style={{ color: 'green', fontWeight: 'bold' }}
              >
                <LinkContainer to='/vendeur/productlist'>
                  <NavDropdown.Item>Produits</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  Déconnexion
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Col>
          <Col className='column_log_user_mobile'>
            <LinkContainer to='/login'>
              {/* selim */}
              <Nav.Link>
                {' '}
                <h5 className={colorAuthentificationUser}>
                  <FaUserLock /> {t('user')}{' '}
                </h5>{' '}
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          {/*  couleur */}
          <Col className='column_langue_mobile'>
            <Nav
              className='langue'
              style={{ color: 'green', fontWeight: 'bold' }}
            >
              <FaFlag />
              {espace} <h6>{t('language')}</h6>{' '}
            </Nav>
            <Form.Control
              as='select'
              value={langueInfo}
              //onChange={(e) => dispatch(setCredentialLangue(e.target.value))}
              //couleur (deux fonctions appelées)
              onChange={(e) => {
                dispatch(setCredentialLangue(e.target.value));
                changeLanguage(e.target.value);
              }}
              //onChange = { (e) => { this.activarBotonEnviar(e); this.contadorDeCaracteres(e) } }
            >
              <option key='fr' value='fr'>
                Français
              </option>
              <option key='en' value='en'>
                English
              </option>
              <option key='ar' value='ar'>
                عربي
              </option>
            </Form.Control>
          </Col>

          <Col className='column_devise_mobile'>
            <Nav
              className='devise'
              style={{ color: 'green', fontWeight: 'bold' }}
            >
              <FaMoneyBill />
              {espace} <h6>{t('devise')}</h6>{' '}
            </Nav>
            {/* <Nav className="devise"><FaMoneyBill/>{espace} Devise | {deviseInfo.devise}</Nav> */}
            {/* <NavDropdown>   */}
            {/* selim */}
            {/* <NavDropdown.Item active> 
                    <Nav> D_TN </Nav>
                   </NavDropdown.Item>
                   <NavDropdown.Item> 
                    <Nav> Euro </Nav>
                   </NavDropdown.Item>
                   <NavDropdown.Item> 
                    <Nav> Dollar </Nav>
                   </NavDropdown.Item>
                 </NavDropdown> */}
            {/*  selim */}
            <Form.Control
              as='select'
              value={deviseInfo}
              onChange={(e) => dispatch(setCredentialDevise(e.target.value))}
            >
              <option key='D_tn' value='D_tn'>
                D_tn
              </option>
              <option key='Euro' value='Euro'>
                Euro
              </option>
              <option key='Dolar' value='Dolar'>
                Dolar
              </option>
            </Form.Control>
          </Col>
        </Row>
      </Container>
      {/*NEW-VENTE*/}
      <Container>
        <Row>
          <Col xs={5} className='column_licorne'>
            <LinkContainer to='/'>
              <Navbar.Brand>
                <img
                  src='../../images/licorne.png'
                  className='d-inline-block align-top'
                  alt='React Bootstrap logo'
                  width='110'
                  height='110'
                />
              </Navbar.Brand>
            </LinkContainer>
          </Col>

          <Col xs={7} className='column_categories_mobile py-5'>
            <NavDropdown title={t('category')} id='basic-nav-dropdown'>
              <NavDropdown.Item>{t('info')}</NavDropdown.Item>
              <NavDropdown.Item>{t('devp')}</NavDropdown.Item>
              <NavDropdown.Item>{t('politique')}</NavDropdown.Item>
            </NavDropdown>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xs={5} className='column_panier_mobile'>
            <LinkContainer to='/cart'>
              {/* color */}
              <Nav className={colorPanier}>
                <FaCartPlus />
                {espace} <h6>{t('cart')}</h6>
                {cartItems.length > 0 && (
                  <Badge pill bg='success' style={{ marginLeft: '5px' }}>
                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                  </Badge>
                )}
              </Nav>
            </LinkContainer>

            <br />
            <hr />

            {/*NEW-VENTE*/}
            <LinkContainer
              to='/other'
              onClick={() => changement_type_produit(2)}
            >
              <Nav.Link>
                <h6 className={colorAutreProduit}>{t('otherProduct')}</h6>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={7} className='column_search_mobile py-5'>
            {/* <Form className="d-flex"> */}
            <SearchBox />
            {/* </Form> */}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderMobile;
