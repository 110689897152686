import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Product = ({ product }) => {
  const { t } = useTranslation();

  //selim
  const { deviseInfo } = useSelector((state) => state.devise);

  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/product/presential/${product._id}`}>
        <Card.Img className='product-image' src={product.image} variant='top' />
      </Link>

      <Card.Body>
        <Link to={`/product/presential/${product._id}`}>
          <Card.Title className='product-title' as='div'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        {/* <Card.Text as='div'>
           <Rating value={product.rating} text={`${product.numReviews} reviews`} />
      </Card.Text>   */}

        {/*  selim  */}
        {deviseInfo === 'D_tn' && (
          <Card.Text as='h3'>
            {/*  couleur  */}
            {t('Price')} {product.price} {deviseInfo}
          </Card.Text>
        )}
        {deviseInfo === 'Euro' && (
          <Card.Text as='h3'>
            {t('Price')} {product.priceEur} {deviseInfo}
          </Card.Text>
        )}
        {deviseInfo === 'Dolar' && (
          <Card.Text as='h3'>
            {t('Price')} {product.priceDol} {deviseInfo}
          </Card.Text>
        )}

        <Card.Text as='p'>
          {t('productDate', {
            date: moment(product.date).format('DD MMMM YYYY'),
          })}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
