import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import './ContactForm.css';
import { toast } from 'react-toastify';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'; 

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { t , i18n} = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';


  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/api/sendmail', { name, email, message })
      .then((res) => {
        setName('');
        setEmail('');
        setMessage('');
        toast.success('Email sent successfully'); // Display success toast
      })
      .catch((error) => {
        toast.error('Email not sent'); // Display error toast
      });
  };
  

  return (
    <Container>
      <div className='center-content'>
        {' '}
        <h4>{t('contactus')} </h4>
      </div>
      <Row dir={isRTL ? 'rtl' : 'ltr'}>
        <Col xs={6} md={6} className='text-start text-start-content'>
          <div className='og'>
            <h4 dir={isRTL ? 'rtl' : 'ltr'}>{t('contact')}</h4>
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
              <strong>{t('address')} </strong>CENTRE D'AFFAIRE LE MONTPLAISIR,
              Av. Kheireddine Pacha, Tunis 1002
            </h6>

            <h6>
              <strong>
                <a href='https://goo.gl/maps/2yhjvD8pEaaV681E9'>
                  {' '}
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> {t('maps')}
                </a>
              </strong>
            </h6>

            <h6>
              <FontAwesomeIcon icon={faEnvelope} />{' '}
              <strong>{t('email')} : </strong>
              <a href='/'>licornexevenement@gmail.com</a>
            </h6>

            <h6>
              <FontAwesomeIcon icon={faPhone} />{' '}
              <strong>{t('phonenumber')} :</strong> +216 50-963-919
            </h6>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className='og2'>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId='formBasicName' className='mb-3'>
                <Form.Label> {t('name')} : </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t('entername')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId='formBasicEmail' className='mb-3'>
                <Form.Label> {t('email')} : </Form.Label>
                <Form.Control
                  type='email'
                  placeholder={t('enteremail')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId='formBasicMessage' className='mb-3'>
                <Form.Label> {t('msg')} </Form.Label>
                <div className='message'>
                  <Form.Control
                    as='textarea'
                    cols={5}
                    placeholder={t('entermsg')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </Form.Group>

              <Button type='submit' className='btn custom-button'>
                {t('submit')}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
